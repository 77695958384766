<template>
  <v-container
    ><v-layout row wrap justify-space-between class="mb-2">
      <v-flex xs12 sm4 md4 lg4 class="py-1 px-1">
        <v-btn
          :color="$store.state.secondaryColor"
          outlined
          small
          @click="goBack"
        >
          <v-icon small left>mdi-arrow-left</v-icon>
          <span class="caption">{{ $t("back") }}</span>
        </v-btn>
      </v-flex>
      <v-spacer></v-spacer>
    </v-layout>

    <!-- ############################### -->

    <div v-if="pleaseWait" class="mt-3">
      <PleaseWait></PleaseWait>
    </div>

    <v-layout row wrap justify="center" align="center" v-else>
      <v-flex xs12 sm12 md12 lg12>
        <span class="text-h4 font-weight-black">{{
          expenseClaims.names.first +
          " " +
          expenseClaims.names.middle +
          " " +
          expenseClaims.names.last
        }}</span>
      </v-flex>
      <v-flex xs12 sm4 md4 lg4>
        <v-card class="mx-auto" max-width="344">
          <v-card-title class="body-1">
            {{ $t("proof of purchase") }}
          </v-card-title>
          <v-divider></v-divider>
          <v-img
            :src="expenseClaims.proofOfPurchase"
            height="200px"
            @click="dialog = true"
          ></v-img>
        </v-card>

        <!-- DISPLAY PRODUCT IMAGE IN LARGE -->

        <v-dialog v-model="dialog" width="550">
          <v-card dark>
            <v-img :src="expenseClaims.proofOfPurchase" contain></v-img>
          </v-card>
        </v-dialog>
      </v-flex>
      <v-flex xs12 sm8 md8 lg8 class="">
        <v-list dense class="elevation-1">
          <v-list-item class="body-1 d-flex flex-wrap">
            <v-col cols="12" md="6">
              <span class="font-weight-black text-capitalize"
                >{{ $t("amount") }}:
              </span>
              <span class="">
                {{ currencyFormat(expenseClaims.amount) }}
              </span>
            </v-col>
            <v-col cols="12" md="6">
              <span class="font-weight-black text-capitalize"
                >{{ $t("date") }}:
              </span>
              <span class="">
                {{ formatDate(expenseClaims.dateTime) }}
              </span>
            </v-col>
            <v-col cols="12" md="12">
              <span class="font-weight-black text-capitalize"
                >{{ $t("description") }}
              </span>
              <v-textarea
                outlined
                disabled
                class="mt-3"
                name="input-7-4"
                :label="$t('description')"
                :value="expenseClaims.description"
              ></v-textarea>
              <span class=""> </span>
            </v-col>
          </v-list-item>

          <v-divider></v-divider>
        </v-list>
      </v-flex>
    </v-layout>
    <!-- ################################### -->
    <!-- ########### -->
  </v-container>
</template>
<script>
import { format } from "date-fns";
import db from "@/plugins/fb";
import PleaseWait from "@/components/templates/PleaseWait";
export default {
  components: {
    PleaseWait,
  },
  data: () => ({
    dialog: false,
    pleaseWait: true,
    expenseClaims: "",
  }),

  created() {
    this.getExpenseClaims();
  },
  methods: {
    getExpenseClaims() {
      this.expenseClaims = "";

      db.collection("expenseClaims")
        .doc(this.$route.params.id)

        .get()
        .then((doc) => {
          this.expenseClaims = doc.data();
          this.filteredExpenseClaims = this.expenseClaims;
          this.pleaseWait = false;
        });
    },
    goBack() {
      window.history.back();
    },

    currencyFormat(val) {
      return this.$store.state.currency.symbol + " " + val;
    },

    formatDate(val) {
      return format(val.toDate(), "EEE, dd MMMM, yyyy");
    },
  },
};
</script>
<style></style>
